import { TranslocoService } from '@jsverse/transloco';
import { TranslocoModule } from '@jsverse/transloco';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { UserTestDate } from '../../../core/interfaces/user-platform';
import { CategoriesService } from '../../../core/categories-service/categories.service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/core/user-service/user.service';
import { UiService } from 'src/app/core/ui-service/ui.service';
import { UtilsService } from 'src/app/core/utils-service/utils.service';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogTitle } from '@angular/material/dialog';
import { User } from 'src/app/core/interfaces/user';
import { Component, Inject, OnInit } from '@angular/core';
import { Swiper } from 'swiper/swiper';
import moment from 'moment';
import { SwiperModule } from 'swiper/angular';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgClass } from '@angular/common';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatIcon } from '@angular/material/icon';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { LottieComponent } from 'ngx-lottie';
import { ConfettiComponent } from '../../components/confetti/confetti.component';
import { ConfigService } from '../../../core/config/config.service';
import { upperCaseFromUnderscore } from '../../../core/util/string.util';
import { getTime } from '../../../core/util/date.util';

@Component({
  selector: 'app-required-user-info-dialog',
  templateUrl: './required-user-info-dialog.component.html',
  styleUrls: ['./required-user-info-dialog.component.scss'],
  standalone: true,
  imports: [
    MatError,
    MatLabel,
    SwiperModule,
    MatDialogTitle,
    TranslocoModule,
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    NgClass,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepicker,
    MatIcon,
    MatSlider,
    MatSliderThumb,
    FormsModule,
    MatDialogActions,
    LottieComponent,
    ConfettiComponent,
  ],
})
export class RequiredUserInfoDialogComponent implements OnInit {
  username_focused = false;

  readonly SENDING_CODE_LATENCY = 1 * 1000; // Millisecondi

  swiper?: Swiper;

  show_slider = false;

  start_animation = false;
  loading = false;

  readonly self_evaulation_emoji = ['cry', 'sad', 'suspicion', 'relieved_face', 'sunglasses'];

  categories = new Array<string>();

  UserTestDateType = UserTestDate;
  test_date_type?: UserTestDate;

  self_evaulation_category: { [category: string]: number } = {};

  daily_hours = 2;
  test_date: string = '';
  expected_score = '';
  MAX_SCORE = 90;

  futura_suggested_day_hours = 3;
  futura_course_duration_hours = 0;

  time_on_page = getTime();

  score_steps: Array<number> = [];

  showConfetti = new Subject<undefined>();

  form_name = new UntypedFormControl('', [Validators.required]);
  form_surname = new UntypedFormControl('', [Validators.required]);
  form_username = new UntypedFormControl('', [Validators.required]);
  form_test_date = new UntypedFormControl('', [Validators.required]);
  form_birth_date = new UntypedFormControl('', [Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: User },
    private utilsService: UtilsService,
    private uiService: UiService,
    private userService: UserService,
    private translate: TranslocoService,
    private categoriesService: CategoriesService,
    configService: ConfigService
  ) {
    configService.getUiConfig('_self_evaluation_category').subscribe(cat => {
      if (cat) this.showEvaluation(cat);
    });
    configService.getSimulationConfig('max_real_test_points').subscribe(val => {
      if (val == 0) return;

      this.MAX_SCORE = val;
      this.score_steps = [Math.round(this.MAX_SCORE * 0.5), Math.round(this.MAX_SCORE * 0.8), this.MAX_SCORE];
      // this.expected_score = this.score_steps[1].toString();
      // this.expected_score = Math.round(this.MAX_SCORE * 0.5) + 1;
    });
    configService.getUiConfig('_course_add_total_duration').subscribe(percent => {
      percent = percent || 0;
      if (percent == -1) return;
      this.categoriesService.getDuration(undefined).subscribe(duration => {
        const futura_seconds_suggest = duration + (duration * percent) / 100;
        this.futura_course_duration_hours = Math.round(futura_seconds_suggest / 60 / 60);
        console.log({ percent, duration, hours: this.futura_course_duration_hours });
      });
    });
  }

  get is_mobile() {
    return this.utilsService.isMobileDevice();
  }

  get dateFormat(): string {
    return (
      moment()
        .locale(localStorage.getItem('language') || environment.locale)
        .localeData() as any
    )._longDateFormat['L'];
  }

  filterPastDate = (d: Date | null): boolean => {
    const day = d || new Date();
    // Prevent Saturday and Sunday from being selected.
    return day.getTime() > new Date().getTime();
  };

  showEvaluation(cat: string) {
    const category = +cat + 1;

    this.userService.getUserCategory().subscribe(categories => {
      this.categories = Array.from(new Set(categories.map(cat => cat.split('.').splice(0, category).join('.'))));

      this.categories.forEach(cat => (this.self_evaulation_category[cat] = 0));
    });
  }

  updateSwiper() {
    setTimeout(() => {
      if (this.swiper) this.swiper.updateAutoHeight(100);
    }, 100);
  }

  changeDailyHours(count: number) {
    this.daily_hours += count;
    this.updateSwiper();
  }

  setDateType(type: UserTestDate) {
    this.test_date_type = type;

    switch (type) {
      case UserTestDate.NotKnow:
        this.test_date = moment(getTime()).add(1, 'years').format('YYYY-MM-DD');
        break;
      case UserTestDate.Soon:
        this.test_date = moment(getTime()).add(1, 'months').format('YYYY-MM-DD');
        break;
      case UserTestDate.Fixed:
        this.test_date = '';
        break;
    }
    this.updateSwiper();
  }

  getDayToTest() {
    const test_date = moment(this.test_date);
    const today = moment();
    const diff = test_date.diff(today, 'days');
    return diff;
  }

  getSuggestDailyHours() {
    return Math.max(this.getFuturaSuggestDailyHours(), this.daily_hours);
  }

  getFuturaSuggestDailyHours() {
    const suggest = Math.round(this.futura_course_duration_hours / this.getDayToTest());
    return Math.max(suggest, 2);
  }

  displayCat(cat: string, only_last = false) {
    // First uppercase
    if (only_last) cat = cat.split('.').pop() || '';
    if (cat == '') return '';
    return cat
      .split('_')
      .map(c => c[0].toUpperCase() + c.slice(1))
      .join(' ');
  }

  validateEmail(email: string): boolean {
    return (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) != null
    );
  }

  onSwiper(swiper: Swiper) {
    this.swiper = swiper;
  }

  prev() {
    if (this.swiper) {
      this.swiper.slidePrev();

      // this.swiper.slidePrev()
    }
  }

  next() {
    if (!this.isCurrentValid()) return;
    return this.save();
  }

  isCurrentValid(): boolean {
    if (!this.swiper) return false;

    // return true;
    const current = this.swiper.activeIndex;

    if (current == 0) return this.firstSlideValid();
    if (current == 1) return this.secondSlideValid();

    return true;
  }

  ngOnInit(): void {
    const phone_info = JSON.parse(localStorage.getItem('_phone_info')!) as {
      countryCode: string;
      dialCode: string;
      number: string;
    };
    // localStorage.removeItem('_phone_info');
    this.data.user.content.phone = phone_info.number;
    this.data.user.content.prefix = phone_info.dialCode;
    this.data.user.content.countryCode = phone_info.countryCode;

    const utmName = localStorage.getItem('utm_name');
    const utmSurname = localStorage.getItem('utm_surname');
    const utmEmail = localStorage.getItem('utm_email');

    if (utmName) {
      this.data.user.content.name = utmName;
      this.form_name.setValue(utmName);
      localStorage.removeItem('utm_name');
    }

    if (utmSurname) {
      this.data.user.content.surname = utmSurname;
      this.form_surname.setValue(utmSurname);
      localStorage.removeItem('utm_surname');
    }

    if (utmEmail) {
      this.data.user.content.email = utmEmail;
      localStorage.removeItem('utm_email');
    }

    if (utmName && utmSurname) {
      this.changeUsername();

      this.uiService.showSpinner = true;
      this.save();
    }
  }

  changeUsername() {
    if (this.username_focused) return;
    this.data.user.content.nickname =
      (this.data.user.content.name.toLowerCase().split(' ').join('') ?? '') + '_' + (this.data.user.content.surname.toLowerCase().split(' ').join('') ?? '');
  }

  firstSlideValid(): boolean {
    this.form_name.markAsTouched();
    this.form_surname.markAsTouched();
    this.form_username.markAsTouched();

    const invalid_element = document.querySelector('.info-container input.ng-invalid') as HTMLElement;
    if (invalid_element) {
      invalid_element.focus();
      // this.uiService.errorToast({title: `Il campo ${invalid_element.getAttribute('name')} non è valido!`})
      this.uiService.errorToast({ title: this.translate.translate('utils.errors.invalid_field', { field: invalid_element.getAttribute('name') || '' }) });
      console.log({ invalid_element });
      return false;
    }

    // if(this.data.user.content.current_study == undefined || this.data.user.content.current_study == '') {
    //   this.uiService.errorToast({title: 'Per proseguire seleziona l\'anno che stai frequentando oppure scegli "Altro"'});
    //   const element = document.getElementById('current_study') as HTMLElement;
    //   element.classList.add('invalid');
    //     setTimeout(() => {
    //       element.classList.remove('invalid');
    //   }, 500)
    //   return false;
    // }

    return this.data.user.content.nickname.trim() != '' && this.data.user.content.name.trim() != '' && this.data.user.content.surname.trim() != '';
  }

  secondSlideValid(): boolean {
    const test_utc_timestamp = moment(this.test_date).utc().toDate().getTime();
    const today_utc_timestamp = moment().utc().startOf('day').toDate().getTime();

    if (+this.expected_score <= 0 || +this.expected_score > this.MAX_SCORE) {
      const element = document.querySelector('.emoji-cards');
      if (element) {
        this.uiService.errorToast({ title: this.translate.translate('dialogs.user_info.second_slide.errors') });
        element.classList.add('invalid');
        setTimeout(() => {
          element.classList.remove('invalid');
        }, 500);
      }
      return false;
    }

    // if(isNaN(test_utc_timestamp) || test_utc_timestamp <= today_utc_timestamp) {
    //   const element = document.querySelector('.fut-segmented-buttons');
    //   if(element) {
    //     if(this.test_date_type == undefined) this.uiService.errorToast({title: this.translate.translate('dialogs.user_info.second_slide.empty_test_date')});
    //     if(this.test_date_type == this.UserTestDateType.Fixed) {
    //       this.form_test_date.markAsTouched();
    //       this.form_name.setErrors({invalid: true});
    //       this.uiService.errorToast({title: this.translate.translate('dialogs.user_info.second_slide.invalid_test_date')});
    //     }

    //     element.classList.add('invalid');
    //     setTimeout(() => {
    //       element.classList.remove('invalid');
    //     }, 500)
    //   }
    //   return false;
    // }

    if (this.daily_hours <= 0 || today_utc_timestamp + this.daily_hours * 60 * 60 * 1000 >= test_utc_timestamp) return false;

    return true;
  }

  getEmojiValue(emoji: string): number {
    return this.self_evaulation_emoji.indexOf(emoji) + 1;
  }

  setSelfEvaulation(category: string, value: number) {
    this.self_evaulation_category[category] = value;
  }

  save() {
    this.data.user.content.name = upperCaseFromUnderscore(this.data.user.content.name);
    this.data.user.content.surname = upperCaseFromUnderscore(this.data.user.content.surname);
    this.data.user.content.birthday = new Date(this.form_birth_date.value).getTime();

    const user_full_name = (this.data.user.content.name + ' ' + this.data.user.content.surname).toUpperCase().split(' ').join('+');
    this.data.user.content.image = {
      provider: 'Futura',
      url: `https://ui-avatars.com/api/?background=152148&font-size=.6&color=fff&name=${user_full_name}&bold=True&size=1024`,
    };

    // this.data.user.content.platforms![environment.platform].expected_points = +this.expected_score;
    // this.data.user.content.platforms![environment.platform].weekly_hours = this.daily_hours;
    // const test_utc_timestamp = moment(this.test_date).utc().toDate().getTime();
    // this.data.user.content.platforms![environment.platform].test_date = test_utc_timestamp;
    // this.data.user.content.platforms![environment.platform].test_date_type = this.test_date_type!;

    // this.data.user.content.platforms![environment.platform].evaluation = this.self_evaulation_category;

    this.uiService.showSpinner = true;
    this.userService.updateFuturaUser(this.data.user).subscribe(() => {
      location.reload();
    });
  }

  endAnimation() {
    setTimeout(() => {
      location.reload();
    }, 5000);
  }
}
