<div [ngClass]="{ collapsed: collapsed() }" class="d-flex flex-column align-items-start w-100">
  <div
    [ngClass]="{ 'justify-content-between': !collapsed(), 'justify-content-center': collapsed() }"
    class="d-flex align-items-center align-self-stretch desktop-logo-wrapper">
    @if (!collapsed()) {
      <img class="d-md-inline-block" [routerLink]="homepage()" [src]="largeLogo()" alt="Futura" id="logo" />
    } @else {
      <img class="d-md-inline-block" [routerLink]="homepage()" [src]="smallLogo()" alt="Futura" id="logoCollapsed" />
    }

    @if (!collapsed()) {
      <img
        ngSrc="/assets/material_icons/left_panel_close_custom.svg"
        alt="background image"
        class="text-white toggle-icon"
        (click)="toggleExpandCollapse()"
        width="24"
        height="24" />
    } @else {
      <div class="circle">
        <mat-icon (click)="toggleExpandCollapse()" fontSet="material-symbols-outlined" class="toggle-icon"> chevron_right </mat-icon>
      </div>
    }
  </div>
  <div class="w-100 d-flex align-items-center justify-content-between mobile-logo-wrapper">
    <img [routerLink]="homepage()" [src]="largeLogo()" alt="Futura" id="logoMobile" />
    <mat-icon fontSet="material-symbols-outlined" (click)="closeSidenav()" class="text-white">close</mat-icon>
  </div>
</div>
