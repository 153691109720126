import { Component, model, signal } from '@angular/core';
import { ConfigService } from '../../../../core/config/config.service';
import { UiService } from '../../../../core/ui-service/ui.service';
import { SettingsService } from '../../../../core/settings/settings.service';
import { SidenavSettings } from '../../../../core/model/settings.model';
import { filter, switchMap } from 'rxjs/operators';
import { DEFAULT_HOMEPAGE } from '../../../../core/config/config.model';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { NgClass, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-logo-section',
  templateUrl: './logo-section.component.html',
  styleUrls: ['./logo-section.component.scss'],
  standalone: true,
  imports: [NgClass, RouterLink, MatIcon, NgOptimizedImage],
})
export class LogoSectionComponent {
  public collapsed = model();
  public readonly homepage = signal<string>('/');
  public largeLogo = signal(this.config.largeLogoUrl);
  public smallLogo = signal(this.config.smallLogoUrl);

  constructor(
    private config: ConfigService,
    private uiService: UiService,
    private settingsService: SettingsService
  ) {
    config.getUiConfig('_homepage').subscribe(homepage => this.homepage.set('/' + (homepage || DEFAULT_HOMEPAGE)));
  }

  public toggleExpandCollapse(): void {
    if (this.collapsed() === undefined) {
      return;
    }
    this.collapsed.set(!this.collapsed());
    const newSettings: SidenavSettings = { open: !this.collapsed() };
    this.settingsService
      .readSetting('sidenav')
      .pipe(
        filter(oldSettings => oldSettings.open !== newSettings.open),
        switchMap(() => this.settingsService.writeSetting('sidenav', newSettings))
      )
      .subscribe();
  }

  public closeSidenav(): void {
    this.uiService.showSidenav = false;
  }
}
